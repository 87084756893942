import * as Sentry from '@sentry/nextjs';
import { NextPageContext } from 'next';
import cookies from 'next-cookies';
import { prisma } from '../../backend/client';
import { getCSRFToken } from '../../backend/lib/csrf';
import { setCookie } from '../../backend/lib/setCookie';
import { sanitizeNext } from '../lib/getUrlWithNext';
import { Auth } from '../scenes/auth';

export default function Login({ next, csrf }: { next: string; csrf: string }) {
  return <Auth type="login" next={next} csrf={csrf} />;
}

export async function getServerSideProps(context: NextPageContext) {
  try {
    let token = cookies(context).token;
    let csrf = await getCSRFToken(context);

    let next = sanitizeNext(
      Array.isArray(context.query.next) ? context.query.next[0] : context.query.next,
      `https://weekrise.com`
    );

    if (token) {
      let session = await prisma.token.findFirst({
        where: {
          key: token
        }
      });

      if (!session) {
        setCookie(context.res, 'token', 'nope', {
          maxAge: 0,
          domain: process.env.NODE_ENV == 'development' ? 'localhost' : 'weekrise.com',
          sameSite: 'lax'
        });
      } else {
        return {
          redirect: {
            destination: next
          }
        };
      }
    }

    return {
      props: {
        csrf,
        next
      }
    };
  } catch (err) {
    console.warn(err);

    Sentry.captureException(err);

    throw err;
  }
}
